import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=61dd7a3d&"
import script from "./Header.vue?vue&type=script&lang=ts&"
export * from "./Header.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\Buildserver\\Agent01\\_work\\664\\s\\JCD.Retail.Webshop\\App\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('61dd7a3d')) {
      api.createRecord('61dd7a3d', component.options)
    } else {
      api.reload('61dd7a3d', component.options)
    }
    module.hot.accept("./Header.vue?vue&type=template&id=61dd7a3d&", function () {
      api.rerender('61dd7a3d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Header.vue"
export default component.exports